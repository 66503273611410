.debate{
  background-color: var(--secondary-color);
}

.debateTitle{
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}

.chart{
  margin: auto;
  display: block;
}

.chart path{
  stroke-width: 5px;
}

.chart circle{
  fill: transparent;
}

.chart text{
  fill: #ffffff;
}

.candidate-chart-container .chart path{
  stroke-width: 2px;
}

.candidate-chart-container{
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}

.candidateText{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stats-row{
  padding: 10px 5px 10px 5px;
}

.stats-col{
  font-size: 14px;
  border: 1px solid #ffffff;
}
